// React Basic and Bootstrap
import React, { Component } from "react";
import { Row, Col } from "reactstrap";

// Import images
import pen from "../../images/icon/pen.svg";
import code from "../../images/icon/code.svg";
import consultoria from "../../images/icon/intellectual.svg";
import devops from "../../images/icon/devops2.svg";
import marketing from "../../images/icon/marketing.svg";
import mvp from "../../images/icon/mvp.svg";
import Link from "../../components/Link"

class Feature extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-5 mt-2 bg-light" id="servicios">
          <div className="container">
            <Row className="justify-content-center">
              <Col className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="main-title mb-4">Servicios</h4>
                  <p className="text-muted para-desc mx-auto mb-0">
                    Creamos productos digitales  
                    <span className="text-primary font-weight-bold">
                    {" "} desde la concepción hasta su salida al mercado,
                    </span>{" "} acompañando a empresas y startups en su crecimiento y logrando la consecución de sus objetivos.
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
            
            <Col md={4} className="col-12 mt-5">
            <div className="features">
              <div className="image position-relative d-inline-block">
                <img src={consultoria} alt="Consultoría Estratégica" />
              </div>

              <div className="content mt-4">
                <h4 className="title-2">Consultoría Estratégica</h4>
                <p className="text-muted mb-0">
                  Damos forma a tu idea de negocio. Evaluamos las tecnologías y arquitectura a utilizar ofreciendo asesoramiento y soluciones de desarrollo que se ajusten a tus necesidades. 
                </p>
              </div>
            </div>
          </Col>

              <Col md={4} className="col-12 mt-5">
                <div className="features">
                  <div className="image position-relative d-inline-block">
                    <img src={pen} alt="Diseño Web | UX & UI" />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">Diseño Web | UX & UI</h4>
                    <p className="text-muted mb-0">
                      Cúentanos tu idea y nuestros diseñadores le darán vida. Ofrecemos diseño de vanguardia creando productos que impresionan y llaman la atención, con especial hincapié en la usabilidad.
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={4} className="col-12 mt-5">
                <div className="features">
                  <div className="image position-relative d-inline-block">
                    <img src={code} alt="Desarrollo web" />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">Desarrollo Web | App</h4>
                    <p className="text-muted mb-0">
                      Tanto si necesitas una aplicación móvil o página web, una <Link className="text-primary" to="https://tienda-online.kedarastudios.com" title="Soluciones E-commerce | Creamos tu tienda online">tienda online</Link> o un desarrollo a medida, estamos contigo. Creamos productos que funcionan para tu negocio y tus clientes.
                    </p>
                  </div>
                </div>
              </Col>
                        

              <Col md={4} className="col-12 mt-5">
                <div className="features">
                  <div className="image position-relative d-inline-block">
                    <img src={mvp} alt="Desarrollo MVP" />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">Desarrollo MVP</h4>
                    <p className="text-muted mb-0">
                    El desarrollo de un MVP (Mínimo Producto Viable) te permite evaluar la viabilidad de tu idea en un periodo corto de tiempo, analizando su cabida en el mercado y minimizando costes antes de lanzarte al desarrollo del producto final.</p>
                  </div>
                </div>
              </Col>
              
              <Col md={4} className="col-12 mt-5">
                <div className="features">
                  <div className="image position-relative d-inline-block">
                    <img src={devops} alt="DevOps" />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">DevOps</h4>
                    <p className="text-muted mb-0">
                      Automatizamos tus procesos de desarrollo de software para compilar, testear, lanzar a producción y optimizar las cargas de trabajo de forma más rápida y eficiente.
                    </p>
                  </div>
                </div>
              </Col>
              
              <Col md={4} className="col-12 mt-5">
              <div className="features">
                <div className="image position-relative d-inline-block">
                  <img src={marketing} alt="Marketing" />
                </div>

                <div className="content mt-4">
                  <h4 className="title-2">Marketing & SEO</h4>
                  <p className="text-muted mb-0">
                    No basta con tener un buen producto, es necesario darlo a conocer, hacerse notar y generar ventas. Para ello definimos la estrategia, ofrecemos campañas PPC, publicidad dirigida en redes sociales, email marketing y SEO.
                  </p>
                </div>
              </div>
            </Col>
            
              
   
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
