// React Basic and Bootstrap
import React, { Component } from "react";
import Layout from "../components/Layout";
import { Row, Col } from "reactstrap";
import Seo from "../components/Seo";
// import images
import services from "../images/services/javaScript-frameworks-pana.svg";

// import generic componenets
//import Partners from "../components/Home/Partners";
import Feature from "../components/Home/Feature";
import HowItWorks from "../components/Home/HowItWorks";
import Testimonials from "../components/Home/Testimonials";
import Cta from "../components/Home/Cta";
import Works from "../components/Home/AllCases";
import ContactForm from "../components/Home/ContactForm";


import { AnchorLink } from "gatsby-plugin-anchor-links";

// Modal Video
//import ModalVideo from "react-modal-video";
//import "../../../node_modules/react-modal-video/scss/modal-video.scss";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    return (
      <>
        <Layout>
          <Seo title="Kedara Studios &bull; Diseño y Desarrollo Web" description="Kedara Studios es una empresa dedicada al desarrollo y diseño web, aplicaciones móviles y hacking ético." keywords={["kedara studios", "kedara", "aplicaciones móviles", "diseño web", "desarrollo web", "hacking ético", "seguridad", "seguridad informática", "responsive design", "diseño ux", "disenño ux", "devops", "marketing", "pentesting", "auditoría seguridad", "consultoría"]} image="https://kedarastudios.com/favicons/og-image.jpg"/>
          <section className="bg-half-170" id="inicio">
            <div className="home-center">
              <div className="home-desc-center">
                <div className="container">
                  <Row className="align-items-center">
                    <Col lg={6} md={6}>
                      <div className="title-heading mt-4">
                        <h1 className="heading mb-3">
                          Diseño y Desarrollo Web
                        </h1>
                        <p className="para-desc text-muted">
                          Somos una empresa dedicada al desarrollo y diseño web, aplicaciones móviles y hacking ético.
                        </p>
                        <div className="watch-video mt-4 pt-2">
                          <AnchorLink
                            to="/#servicios"
                            className="btn btn-primary mb-2 mr-2"
                          >
                            Nuestros Servicios
                          </AnchorLink>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <img src={services} alt="diseño y desarrollo web" />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </section>

          {/* Partners 
          <Partners/>
          */}

          {/* Feature */}
          <Feature />
          
          {/* HowItWorks*
          <HowItWorks />
          <Works />/}
          {/* Testimonials */}
          <Testimonials />

          {/* Cta */}
          <Cta />

          {/* Contact */}
          <ContactForm />
        </Layout>
      </>
    );
  }
}
export default Index;
