import React from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { Link } from "gatsby"
import * as emailjs from "@emailjs/browser"
import { isBrowser } from "react-device-detect"
import Recaptcha from "react-recaptcha"
import { Row, Col } from "reactstrap";
// import images
import bitcoin from "../../images/icon/bitcoin.svg";
import Email from "../../images/icon/Email.svg";
import contact from "../../images/services/team-pana.svg";

const to = "contacto@kedarastudios.com"
let recaptchaInstance
const resetRecaptcha = () => {
  recaptchaInstance.reset()
}
const ContactForm = () => (
  <>
  <section className="section" id="contacto">
  <div className="container">
    <Row className="justify-content-center">
      <Col className="text-center">
        <div className="section-title mb-60">
          <h4 className="title mb-4">Contacto</h4>
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <div className="contact-detail text-center">
          <div className="icon">
            <img src={bitcoin} alt="Teléfono" />
          </div>
          <div className="content mt-3">
            <h4 className="title font-weight-bold">Teléfono</h4>
            <p className="text-muted">
              Luenes a Viernes de 09:00h a 18:00h
            </p>
            <a href="tel:+34675142310" className="mailgo text-primary">
              +34 675 142 310
            </a>
          </div>
        </div>
      </Col>

      <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div className="contact-detail text-center">
          <div className="icon">
            <img src={Email} alt="Email" />
          </div>
          <div className="content mt-3">
            <h4 className="title font-weight-bold">Email</h4>
            <p className="text-muted">
              Contacta con nosotros en la dirección
            </p>
            <a
              href="mailto:contacto@kedarastudios.com"
              className="mailgo text-primary"
            >
              contacto@kedarastudios.com
            </a>
          </div>
        </div>
      </Col>
    </Row>
  </div>

  <div className="container mt-100 mt-60">
            <Row className="align-items-center">
              <Col
                lg={5}
                md={6}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
              >
                <div className="pt-5 pb-5 p-4 bg-light shadow rounded">
                  <h4>Contacta con nosotros</h4>

    <div className="contact-form my-4">
      <Formik
        initialStatus={{ success: "", css: "" }}
        initialValues={{
          nombre: "",
          email: "",
          telefono: "",
          asunto: "",
          mensaje: "",
          "g-recaptcha-response": "",
          recaptcha: "",
          privacidad: false,
        }}
        validationSchema={Yup.object().shape({
          nombre: Yup.string().required("Escriba su nombre y apellidos"),
          telefono: Yup.number().required("Escriba su teléfono"),
          email: Yup.string()
            .email("Correo electrónico no válido")
            .required("Escriba su correo electrónico"),
          asunto: Yup.string().required("Escriba un asunto"),
          mensaje: Yup.string()
            .required("Escriba un mensaje")
            .min(30, "El mensaje es demasiado corto"),
          recaptcha: Yup.string().required("Debe rellenar el recaptcha"),
          privacidad: Yup.bool().oneOf(
            [true],
            "Debe aceptar los términos y condiciones de uso."
          ),
        })}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            let templateParams = values
            templateParams.to = to

            emailjs
              .send(
                "default_service",
                "template_v0vu4di",
                templateParams,
                "user_DR4yyOP1IwXRHAPzK9kz0"
              )
              .then(
                function(response) {
                  actions.resetForm()
                  actions.setStatus({
                    success: "¡Mensaje enviado correctamente!",
                    css: "success",
                  })
                  resetRecaptcha()
                  actions.resetForm()
                  actions.setSubmitting(false)
                },
                function(err) {
                  actions.setStatus({
                    success:
                      "Ha ocurrido un error al enviar el mensaje. Inténtelo de nuevo más tarde.",
                    css: "danger",
                  })
                  actions.resetForm()
                  resetRecaptcha()
                }
              )
          }, 400)
        }}
      >
        {({ values, errors, touched, isSubmitting, status, setFieldValue }) => (
          <Form className="form">
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <Field
                    type="text"
                    name="nombre"
                    placeholder="Nombre y apellidos"
                    className={
                      "form-control" +
                      (errors.nombre && touched.nombre ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="nombre"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-6">
                <div className="form-group">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <Field
                    type="tel"
                    name="telefono"
                    placeholder="Teléfono móvil"
                    className={
                      "form-control" +
                      (errors.telefono && touched.telefono ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="telefono"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <Field
                    type="text"
                    name="asunto"
                    placeholder="Asunto"
                    className={
                      "form-control" +
                      (errors.asunto && touched.asunto ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="asunto"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <Field
                    component="textarea"
                    name="mensaje"
                    placeholder="Mensaje"
                    className={
                      "form-control" +
                      (errors.mensaje && touched.mensaje ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="mensaje"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
            <div className="form-row grey">
              <div className="col">
                <div className="form-check">
                  <Field
                    name="privacidad"
                    type="checkbox"
                    checked={values.privacidad}
                    className={
                      "form-check-input checkbox" +
                      (errors.privacidad && touched.privacidad
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <label className="form-check-label" htmlFor="privacidad">
                    He leído y acepto la{" "}
                    <Link
                      to="politica-de-privacidad"
                      className="text-primary"
                    >
                      política de privacidad
                    </Link>
                  </label>
                  <ErrorMessage
                    name="privacidad"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
            <div className="form-row grey mt-4">
              <div className="col">
                {isBrowser && (
                  <Recaptcha
                    ref={e => (recaptchaInstance = e)}
                    sitekey="6Lfb6jogAAAAAJ6lcyL4mWv-EciJr8j4-R8ysesu"
                    render="explicit"
                    verifyCallback={response => {
                      setFieldValue("g-recaptcha-response", response)
                      setFieldValue("recaptcha", response)
                    }}
                  />
                )}
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="invalid-feedback"
                />
                <div className="recaptcha-response">
                  {errors.recaptcha && touched.recaptcha && (
                    <p>{errors.recaptcha}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-row grey mt-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="submitBnt btn btn-primary btn-block"
              >
                Enviar Mensaje
              </button>
            </div>

            {status && status.success ? (
              <div className={` my-3 alert alert-${status.css}`}>
                <h4>{status.success}</h4>
              </div>
            ) : (
              <></>
            )}
          </Form>
        )}
       
      </Formik>
    </div>
    </div>
    </Col>
    <Col lg={7} md={6} className="order-1 order-md-2">
                <img
                  src={contact}
                  className="img-fluid"
                  alt="Contacta con nosotros"
                />
              </Col>
            </Row>
          </div>
        </section>
  </>
)

export default ContactForm
