// React Basic and Bootstrap
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Link from "../Link";

//import images
import estrategia from "../../images/services/scrum-board-pana.svg";
import diseno from "../../images/services/usability-testing-pana.svg";
import desarrollo from "../../images/services/version-control-pana.svg";
import hosting3 from "../../images/services/social-strategy-pana.svg";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
      <div className="module-wrapper" id="proceso">
                <div className="module-header module-header__left">
                  <div className="module-header__content">
                    <div className="module-header__title">
                        <div className="module-header__count">
                          01
                        </div>
                      <h2 className="module-header__title--content">
                          Estrategia y <span>Evaluación</span>
                      </h2>
                    </div>
                    <div>
                      <div className="hs_cos_wrapper_type_inline_rich_text module-header__caption">
                      <p>Give your product idea a shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate possible mistakes.</p>
                     </div>
                  </div>
              </div>
        </div>
        </div>
        <section className="section bg-light">
          <div className="container">
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <img src={estrategia} className="img-fluid" alt="Estrategia y Evaluación" />
              </Col>

              <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title ml-lg-5">
                  <h4 className="title mb-4">
                    Get best plan for more power with cloud Hosting
                  </h4>
                  <p className="text-muted">
                    You can combine all the Landrick templates into a single
                    one, you can take a component from the Application theme and
                    use it in the Website.
                  </p>
                  <ul className="list-unstyled feature-list text-muted">
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Digital Marketing Solutions for Tomorrow
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Our Talented & Experienced Marketing Agency
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Create your own skin to match your brand
                    </li>
                  </ul>
                  <Link to="#" className="btn btn-primary mt-3">
                    Get Started <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          </section>
          <div className="module-wrapper">
                <div className="module-header module-header__left">
                  <div className="module-header__content">
                    <div className="module-header__title">
                        <div className="module-header__count">
                          02
                        </div>
                      <h2 className="module-header__title--content">
                          Diseño Web <span>UX & UI</span>
                      </h2>
                    </div>
                    <div>
                      <div className="hs_cos_wrapper_type_inline_rich_text module-header__caption">
                      <p>Give your product idea a shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate possible mistakes.</p>
                     </div>
                  </div>
              </div>
        </div>
        </div>
      <section className="section bg-light">
          <div className="container">
            <Row className="align-items-center">
              <Col
                lg={6}
                md={6}
                className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <div className="section-title">
                  <h4 className="title mb-4">
                    Don't Compromise with the best web hosting solutions
                  </h4>
                  <p className="text-muted">
                    Using Landrick to build your site means never worrying about
                    designing another page or cross browser compatibility. Our
                    ever-growing library of components and pre-designed layouts
                    will make your life easier.
                  </p>
                  <ul className="list-unstyled feature-list text-muted">
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Digital Marketing Solutions for Tomorrow
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Our Talented & Experienced Marketing Agency
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Create your own skin to match your brand
                    </li>
                  </ul>
                  <Link to="#" className="btn btn-primary mt-3">
                    Get Started <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </Col>

              <Col lg={6} md={6} className="order-1 order-md-2">
                <img src={diseno} className="img-fluid" alt="Diseño Web | UX & UI" />
              </Col>
            </Row>
          </div>
          </section>
          <div className="module-wrapper">
                <div className="module-header module-header__left">
                  <div className="module-header__content">
                    <div className="module-header__title">
                        <div className="module-header__count">
                          03
                        </div>
                      <h2 className="module-header__title--content">
                          Desarrollo <span>Web</span>
                      </h2>
                    </div>
                    <div>
                      <div className="hs_cos_wrapper_type_inline_rich_text module-header__caption">
                      <p>Give your product idea a shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate possible mistakes.</p>
                     </div>
                  </div>
              </div>
        </div>
        </div>
      <section className="section bg-light">

          <div className="container">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img src={desarrollo} className="img-fluid" alt="Desarrollo Web" />
              </Col>

              <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title ml-lg-5">
                  <h4 className="title mb-4">
                    Powerful Server & Web Hosting Plateform
                  </h4>
                  <p className="text-muted">
                    You can combine all the Landrick templates into a single
                    one, you can take a component from the Application theme and
                    use it in the Website.
                  </p>
                  <ul className="list-unstyled feature-list text-muted">
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Digital Marketing Solutions for Tomorrow
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Our Talented & Experienced Marketing Agency
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Create your own skin to match your brand
                    </li>
                  </ul>
                  <Link to="#" className="btn btn-primary mt-3">
                    Get Started <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <div className="module-wrapper">
                <div className="module-header module-header__left">
                  <div className="module-header__content">
                    <div className="module-header__title">
                        <div className="module-header__count">
                          04
                        </div>
                      <h2 className="module-header__title--content">
                          Marketing <span>& SEO</span>
                      </h2>
                    </div>
                    <div>
                      <div className=" hs_cos_wrapper_type_inline_rich_text module-header__caption">
                      <p>Give your product idea a shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate possible mistakes.</p>
                     </div>
                  </div>
              </div>
        </div>
        </div>
      <section className="section bg-light">
          <div className="container">
            <Row className="align-items-center">
              <Col
                lg={6}
                md={6}
                className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <div className="section-title">
                  <h4 className="title mb-4">
                    Don't Compromise with the best web hosting solutions
                  </h4>
                  <p className="text-muted">
                    Using Landrick to build your site means never worrying about
                    designing another page or cross browser compatibility. Our
                    ever-growing library of components and pre-designed layouts
                    will make your life easier.
                  </p>
                  <ul className="list-unstyled feature-list text-muted">
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Digital Marketing Solutions for Tomorrow
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Our Talented & Experienced Marketing Agency
                    </li>
                    <li>
                      <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                      Create your own skin to match your brand
                    </li>
                  </ul>
                  <Link to="#" className="btn btn-primary mt-3">
                    Get Started <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </Col>

              <Col lg={6} md={6} className="order-1 order-md-2">
                <img src={hosting3} className="img-fluid" alt="Marketin & SEO" />
              </Col>
            </Row>
          </div>
          </section>
      </React.Fragment>
    );
  }
}

export default HowItWorks;
