// React Basic and Bootstrap

import React, { Component } from "react";
import { Row, Col } from "reactstrap";
//import images
import client1 from "../../images/client/buceo-la-restinga-logo.jpg";
import client2 from "../../images/client/youredsocial-logo.png";
import client3 from "../../images/client/aventurate-a-viajar-logo.jpg";
import client4 from "../../images/client/psicologa-sandra-bernal.jpeg";
import client5 from "../../images/client/cuenca-viajes-logo.png";
import client6 from "../../images/client/begona.jpg";
import client7 from "../../images/client/sara.jpg";

// RBCarousel Declare
import { loadableRBCarousel as RBCarousel } from "../loadable-react-bootstrap-carousel";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import "../../css/carousel.css";
const isBrowser = typeof window !== `undefined`;
const items = [
  {
    id: 1,
    image: client2,
    title: "Paula Venancio",
    company: "Youred Social",
    description:
      "Trabajar con el equipo de Kedara Studios, es trabajar sobre seguro. Puedo hablar en primera persona ya que han desarrollado para mi diferentes Identidades corporativas así como webs de todo tipo. Por supuesto que los recomiendo con los ojos cerrados.",
  },
  {
    id: 2,
    image: client3,
    title: "Aventúrate a Viajar",
    company: "Agencia de Viajes",
    description:
      "Grandes profesionales. Muchas gracias y enhorabuena a Dani, que nos hizo la web totalmente a la medida de nuestras necesidades y funcionalidades. Muy satisfechos con el resultado, sin duda 100% recomendable.",
  },
  {
    id: 3,
    image: client1,
    title: "Pilar García",
    company: "Buceo La Restinga",
    description:
      "Recomiendo Kedara Studios al 100%! Grandes  profesionales, que al finalizar su trabajo os dejaran boquiaviertos! Enhorabuena Daniel por todo tu trabajo, agradecerte también la paciencia con nosotrxs... La Web que tenemos nos enamora!! un abrazo!!",
  },
  {
    id: 4,
    image: client5,
    company: "Agencia de Viajes",
    description:
      "Muy satisfechos con la web y el servicio que nos ha proporcionado  Kedara, un gran trabajo, totalmente adaptado a lo que necesitábamos y con un tiempo de entrega excelente.Muchas gracias y enhorabuena!",
    title: "Cuenca Viajes",
  },
  {
    id: 5,
    image: client4,
    title: "Sandra Bernal",
    company: "Psicóloga",
    description:
      "Muy profesional. Me encanta la página web y todo el trabajo que ha realizado después. Sin duda, no habría sido lo mismo sin haber trabajado con él.",
  },
  {
    id: 6,
    image: client6,
    title: "Begoña López",
    company: "PetitPetú",
    description:
      "Una atención extraordinaria super personalizada. Siempre que he solicitado ayuda no han tardado en responder. Trato super cercano! Volvería a repetir una y mil veces!!!",
  },
  {
    id: 7,
    image: client7,
    title: "Sara Villar Gonzalez",
    company: "Terapeuta Gestalt",
    description:
    "Me hicieron mi página web y estoy muy contenta con el resultado, ahora puedo atender a mis pacientes de forma online. 100% recomendables!",
  },
  {
    id: 8,
    image: client7,
    title: "Juan Carlos Antonio Corrales",
    company: "JC Training Store",
    description:
    "Gente muy atenta y profesional. Me diseñaron la web en tiempo récord y el servido de mantenimiento es estupendo",
  },

  
];

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true,
      itemCount: 2, //
      cols: 6,
      total: 8,
    };
  }

  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };
  _changeIcon = () => {
    let { leftIcon, rightIcon } = this.state;
    leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
    rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
    this.setState({ leftIcon, rightIcon });
  };

  componentDidMount() {
    if (isBrowser) {
      window.addEventListener("resize", this.updateWindowSize);
      this.updateWindowSize();
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener("resize", this.updateWindowSize);
    }
  }

  updateWindowSize = () => {
    if (isBrowser) {
      if (window.outerWidth >= 1230) {
        this.setState({ itemCount: 2, cols: 6 });
      } else if (window.outerWidth >= 970 && window.outerWidth < 1230) {
        this.setState({ itemCount: 2, cols: 6 });
      } else if (window.outerWidth <= 970) {
        this.setState({ itemCount: 1, cols: 12 });
      }
    }
  };

  showItems = () => {
    var itemsData = [];
    var data = [];
    for (var i = 0; i < this.state.total; i++) {
      if (i % this.state.itemCount === 0 && i > 0) {
        data.push(
          <div className="item" key={i}>
            <div className="row">{itemsData}</div>
          </div>
        );
        itemsData = [];
      }
      itemsData.push(
        <div className={`col-md-${this.state.cols}`} key={items[i].id}>
          <div className="customer-testi text-left">
            <img
              src={items[i].image}
              height="80"
              className="rounded shadow float-left mr-3 clientes-img"
              alt=""
            />
            <div className="content overflow-hidden d-block p-3 shadow rounded bg-white">
              <ul className="list-unstyled mb-0">
                <li className="list-inline-item">
                  <i className="mdi mdi-star text-warning"></i>
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star text-warning"></i>
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star text-warning"></i>
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star text-warning"></i>
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star text-warning"></i>
                </li>
              </ul>
              <p className="text-muted mt-2">"{items[i].description}"</p>
              <h6 className="text-primary">
                - {items[i].title}{" "}
                <small className="text-muted">{items[i].company}</small>
              </h6>
            </div>
          </div>
        </div>
      );
    }
    if (itemsData) {
      data.push(
        <div className="item" key={i}>
          <div className="row">{itemsData}</div>
        </div>
      );
    }
    return data;
  };

  render() {
    return (
      <React.Fragment>
        <section className="section" id="clientes">
          <div className="container">
            <Row className="justify-content-center">
              <Col className="text-center">
                <div className="section-title mb-60">
                  <h4 className="main-title mb-4">Nuestros clientes</h4>
                  <p className="text-muted para-desc mx-auto mb-0">
                    Las opiniones
                    <span className="text-primary font-weight-bold">
                      {" "}
                      de nuestros clientes{" "}
                    </span>
                    son el mejor reflejo de nuestro trabajo y nuestra mayor
                    satisfacción.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <div id="customer-testi" className="owl-carousel owl-theme">
                  <RBCarousel
                    version={4}
                    autoplay={this.state.autoplay}
                    pauseOnVisibility={false}
                    onSelect={this.visiableOnSelect}
                    slideshowSpeed={4000}
                  >
                    {this.showItems()}
                  </RBCarousel>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;
