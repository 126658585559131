/* eslint-disable import/no-anonymous-default-export */
// React Basic and Bootstrap
import React from "react";
import { Row, Col } from "reactstrap";
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image"
//Parallax

import { Parallax } from "react-parallax";

export default () => (
  <StaticQuery
    query={graphql`query bgimage {
    image: file(relativePath: {eq: "event/cta.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`}
    render={(data) => (
      
      <Parallax
        bgImage={getSrc(data.image)}
        strength={400}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: percentage * 500,
              height: percentage * 500,
            }}
          />
        )}
      >
        <section className="section bg-cta" id="cta">
          <div className="bg-overlay bg-overlay-gradient"></div>
          <div className="container">
            <Row className="justify-content-center">
              <Col className="text-center">
                <div className="section-title">
                  <h4 className="main-title text-white mb-4">
                  <strong>Comienza tu proyecto</strong> con nosotros</h4>
                  <p className="text-light para-desc mx-auto">
                  Tanto si quieres realizar un proyecto nuevo, como si ya tienes uno en marcha, te ayudamos a llevarlo al siguiente nivel. 
                  </p>
                  <AnchorLink to="/#contacto" className="btn btn-primary mt-4">
                    ¿Hablamos? <i className="mdi mdi-arrow-right"></i>
                  </AnchorLink>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </Parallax>
    )}
  />
);
