import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

//Fade in effect
import FadeIn from "react-fade-in";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Images

import work1 from "../../images/work/1.png";
import work2 from "../../images/work/2.png";
import work3 from "../../images/work/3.png";
import work4 from "../../images/work/4.png";
import work5 from "../../images/work/5.png";
import work6 from "../../images/work/6.png";

class AllCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      works: [
        {
          image: work1,
          title: "Austral Ventures",
          isBusiness: true,
          isFinance:true,
          category: "Business",
        },
        {
          image: work2,
          title: "Esfera Capital",
          isFinance:true,
          category: "Business",
        },
        {
          image: work3,
          title: "Aventúrate a Viajar",
          isBusiness: true,
          isFinance:true,
          isMarketing: true,
          category: "Business",
        },
        {
          image: work4,
          title: "Psicóloga Sandra Bernal",
          isBusiness: true,
          isFinance:true,
          category: "Business",
        },
        {
          image: work5,
          title: "Cuenca Viajes",
          isBusiness: true,
          isFinance:true,
          isMarketing: true,
          category: "Business",
        },
        {
          image: work6,
          title: "Buceo La Restinga",
          isBusiness: true,
          isFinance:true,
          category: "Business",
        },
      ],
      displayCategory: "All",
    };
    this.setCategory.bind(this);
  }

  setCategory(category) {
    this.setState({
      displayCategory: category,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" >
          <Container>
            <Row id="works">
              <ul
                className="col container-filter list-unstyled categories-filter text-center mb-0"
                id="filter"
              >
                <li className="list-inline-item">
                  <Link
                    to="#works"
                    onClick={() => this.setCategory("All")}
                    className={
                      this.state.displayCategory === "All"
                        ? "categories border d-block text-dark rounded active"
                        : "categories border d-block text-dark rounded"
                    }
                  >
                    All
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#works"
                    onClick={() => this.setCategory("Business")}
                    className={
                      this.state.displayCategory === "Branding"
                        ? "categories border d-block text-dark rounded active"
                        : "categories border d-block text-dark rounded"
                    }
                  >
                  Diseño Web | UX & UI
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#works"
                    onClick={() => this.setCategory("Marketing")}
                    className={
                      this.state.displayCategory === "Designing"
                        ? "categories border d-block text-dark rounded active"
                        : "categories border d-block text-dark rounded"
                    }
                  >
                    Marketing & SEO
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#works"
                    onClick={() => this.setCategory("Finanace")}
                    className={
                      this.state.displayCategory === "Photography"
                        ? "categories border d-block text-dark rounded active"
                        : "categories border d-block text-dark rounded"
                    }
                  >
                  Desarrollo Web
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    onClick={() => this.setCategory("Human Research")}
                    className={
                      this.state.displayCategory === "Development"
                        ? "categories border d-block text-dark rounded active"
                        : "categories border d-block text-dark rounded"
                    }
                  >
                    Human Research
                  </Link>
                </li>
              </ul>
            </Row>

            <Row className="projects-wrapper">
              {this.state.works
                .filter(
                  ({ category }) =>
                    this.state.displayCategory === category ||
                    this.state.displayCategory === "All"
                )
                .map((cases, key) => (
                  <Col
                    key={key}
                    lg={4}
                    md={6}
                    xs={12}
                    className="mt-4 pt-2 business"
                  >
                    <FadeIn delay={100}>
                      <Card className="blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                          <img
                          src={cases.image}
                          className="img-fluid rounded work-image"
                          alt="Landrick"
                        />
                        <CardBody>
                          <div className="content">
                            {cases.isBusiness && (
                              <Link to="#" className="badge badge-primary mr-1">
                              Diseño Web | UX & UI
                              </Link>
                            )}
                            {cases.isMarketing && (
                              <Link to="#" className="badge badge-warning mr-1">
                                Marketing & SEO
                              </Link>
                            )}
                            {cases.isFinance && (
                                <Link to="#" className="badge badge-info mr-1">Desarrollo Web</Link>
                            )}
                            {cases.isHR && (
                                <Link to="#" className="badge badge-info mr-1">HR</Link>
                            )}
                            <h5 className="mt-3">
                              <Link
                                to="page-case-detail"
                                className="text-dark title"
                              >
                                {cases.title}
                              </Link>
                            </h5>
                            <p className="text-muted">
                              This is required when, for example, the final text
                              is not yet available.
                            </p>
                            <Link to="#" className="text-primary h6">
                              Leer Más{" "}
                              <FeatherIcon
                                icon="arrow-right"
                                className="fea icon-sm"
                              />
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </FadeIn>
                  </Col>
                ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AllCases;
